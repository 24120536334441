import React from "react"
import { FaUser, FaEnvelope, FaPhoneAlt } from "react-icons/fa"
import dots from "~/images/orange-dots.png"

export const UserValidationSkeleton = () => {
  return (
    <div role="status" className="container py-8 md:py-16">
      <div className="grid min-h-screen place-items-center duration-[3s]">
        <div className="grid place-items-center gap-4">
          <div className="relative grid h-12 w-12 place-items-center rounded-full bg-zinc-300">
            <FaUser className="z-10 h-6 w-6 animate-pulse text-zinc-100" />
            <div className="inset-[-3px 0 0 -3px] absolute grid place-items-center">
              <Spinner />
            </div>
          </div>
          <p className="animate-pulse text-zinc-500">Validating user...</p>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export const Spinner = () => {
  return (
    <svg
      aria-hidden="true"
      className={`h-14 w-14 animate-spin fill-theme-orange text-zinc-300`}
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  )
}

export const SupplierPostSkeleton = () => {
  return (
    <div
      role="status"
      className={`flex-set-0-0 relative mx-auto mb-10 flex basis-full flex-row flex-wrap md:flex-row-reverse md:flex-nowrap md:items-center`}
    >
      <div
        className={`card-preview vs-shadow flex-set-0-0 z-[2] mx-auto -mb-10 max-w-[420px] basis-5/6 overflow-hidden rounded-[32px] bg-theme-white md:ml-[-490px] md:mr-[60px] md:mb-0 lg:basis-almost-5/6`}
      >
        <div className={`image relative`}>
          <ImageSkeleton />
          <div
            className={`bar absolute block h-2 w-[calc(100%_-_100px)] bg-theme-orange`}
          />
          <div
            className={`logo absolute bottom-[-36px] right-9 flex h-[72px] w-[72px] items-center justify-center rounded-full border-8 border-theme-orange bg-theme-white p-1`}
          >
            <ImageSkeleton shape="square" noWrap={true} size="small" />
          </div>
        </div>
        <div className={`con-wrap px-11 pb-16 pt-14`}>
          <TextSkeleton />
        </div>
      </div>
      <div
        className={`flex-set-0-0 relative z-[1] basis-full rounded-[32px] bg-theme-gray-light/30 p-6 pt-16 md:py-[72px] md:pl-[7%] md:pr-[530px] xl:pl-[10%]`}
      >
        <TextSkeleton />
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export const TextSkeleton = ({ paragraphs = 1 }: { paragraphs?: number }) => {
  return (
    <div className="max-w-sm animate-pulse">
      <div className="mb-4 h-3.5 w-48 rounded-full bg-zinc-400"></div>
      {Array.from(Array(paragraphs)).map((_, i) => (
        <div key={i} className="mb-6">
          <div className="mb-2.5 h-2 max-w-[360px] rounded-full bg-zinc-400"></div>
          <div className="mb-2.5 h-2 rounded-full bg-zinc-400"></div>
          <div className="mb-2.5 h-2 max-w-[330px] rounded-full bg-zinc-400"></div>
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-zinc-400"></div>
          <div className="h-2 max-w-[360px] rounded-full bg-zinc-400"></div>
        </div>
      ))}
    </div>
  )
}

export const ImageSkeleton = ({
  shape,
  noWrap,
  size,
}: {
  shape?: "square"
  noWrap?: boolean
  size?: "small" | "medium" | "large"
}) => {
  const aspect = shape === "square" ? "aspect-square" : "aspect-[1120/630]"

  const sizeClass =
    size === "small"
      ? "h-8 w-8"
      : size === "medium"
      ? "h-12 w-12"
      : size === "large"
      ? "h-16 w-16"
      : "h-12 w-12"

  const Svg = (
    <svg
      className={`${sizeClass} text-zinc-400`}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      fill="currentColor"
      viewBox="0 0 640 512"
    >
      <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
    </svg>
  )

  if (noWrap) return Svg

  return (
    <div className={`grid place-items-center bg-zinc-300 ${aspect}`}>{Svg}</div>
  )
}

export const CardSkeleton = () => {
  return (
    <div
      className={`post vs-shadow overflow-hidden rounded-[32px] bg-theme-white`}
    >
      <div className={`relative`}>
        <ImageSkeleton />
        <div className="logo absolute right-11 -bottom-11 z-[2] flex h-[88px] w-[88px] items-center justify-center overflow-hidden rounded-full border-8 border-theme-orange bg-theme-white p-1">
          <svg
            className="h-8 w-8 text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 640 512"
          >
            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
          </svg>
        </div>
      </div>
      <div
        className={`con-wrap relative p-6 pt-16 md:p-10 md:pt-20 [&_p]:mb-6 [&_p]:[&_p]:text-small [&_p]:md:mb-12`}
      >
        <div
          className={`line absolute bottom-full left-0 block h-[6px] w-4/5 bg-theme-orange `}
        />
        <div className="mb-8 w-full animate-pulse md:mb-12">
          <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="mb-2.5 h-2 max-w-[480px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="mb-2.5 h-2 max-w-[440px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="mb-2.5 h-2 max-w-[460px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-2 max-w-[360px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
        </div>
      </div>
    </div>
  )
}

export const CardSkeletons = () => {
  return (
    <div className="mb-11 grid w-full grid-cols-1 gap-6 px-3 md:grid-cols-3">
      {[...Array(3)].map((_, i) => (
        <CardSkeleton key={i} />
      ))}
    </div>
  )
}

export const AccountManagerSkeleton = () => {
  return (
    <div
      className={`account-manager relative mx-auto basis-almost-full md:basis-1/2 lg:basis-5/12`}
    >
      <img
        src={dots}
        className={`absolute top-[-60px] left-[20px] z-[1] w-[328px]`}
      />
      <div
        className={`vs-shadow relative z-[2] mx-auto w-full max-w-[318px] overflow-hidden rounded-[32px] bg-theme-white`}
      >
        <div className={`image block h-[142px] bg-theme-green`}></div>
        <div
          className={`mx-auto mt-[-110px] h-[220px] w-[220px] overflow-hidden rounded-full`}
        >
          <ImageSkeleton shape="square" />
        </div>
        {/* <h4
            className={`block px-4 pt-6 pb-8 text-center text-h5 md:text-h5`}
          >{`${accountManager?.forename} ${accountManager?.surname}`}</h4> */}
        <div className="px-4 pt-6 pb-8">
          <TextSkeleton />
        </div>
        <div
          className={`grid grid-cols-2 border-t border-theme-gray-light opacity-60`}
        >
          <div className={`py-5 px-3 text-center font-bold`}>
            <i
              className={`mx-auto block h-6 w-6 text-theme-orange [&_svg]:h-6 [&_svg]:w-6`}
            >
              <FaPhoneAlt />
            </i>
            Call Me
          </div>
          <div
            className={`border-l border-theme-gray-light py-5 px-3 text-center font-bold`}
          >
            <i
              className={`mx-auto block h-6 w-6 text-theme-orange [&_svg]:h-6 [&_svg]:w-6`}
            >
              <FaEnvelope />
            </i>
            Email Me
          </div>
        </div>
      </div>
    </div>
  )
}

export const PageSkeleton = () => {
  return (
    <div className="grid min-h-[calc(100vh-100px)] place-items-center">
      <Spinner />
    </div>
  )
}
