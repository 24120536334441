import React, { useEffect } from "react"
import Layout from "../components/Layout"
import { graphql, navigate } from "gatsby"
import { buildBlocks } from "../services/builder"
import { CoreFields } from "./fragment" // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock" // eslint-disable-line
import Wrapper from "~/components/reusable/Wrapper"
import useUser from "~/hooks/useUser"
import { Spinner } from "~/components/reusable/Skeleton"
import Spacer from "~/components/reusable/svgs/Spacer"
import Button, { ButtonLink } from "~/components/reusable/Button"

const Post = ({ data, pageContext }) => {
  pageContext.pageTitle = data.wpPost.Meta.title
    ? data.wpPost.Meta.title
    : data.wpPost.title
  pageContext.date = data.wpPost.date
  pageContext.cats = data.wpPost.categories
  const blocks = buildBlocks(data.wpPost.blocks, {}, pageContext)
  const { loggedIn } = useUser()

  const endDate = data?.wpPost?.newsPostDetails?.endDate
  const isExpired = endDate ? new Date(endDate) < new Date() : false

  if (isExpired) {
    return <Expired />
  }

  return (
    <Layout
      meta={data.wpPost.Meta}
      path={pageContext.pagePath}
      title={data.wpPost.title}
    >
      {blocks}
      {!loggedIn ? (
        <div className="bg-theme-yellow py-8 md:py-16">
          <div className="relative mx-auto max-w-[90%] md:max-w-2/3">
            <div className="relative w-full rounded-2xl bg-theme-green py-6 px-8 md:py-12">
              <div className="flex items-center justify-between max-md:flex-col">
                <p className="p-4 text-h4 font-bold max-md:text-center md:px-8 md:text-h3">
                  Looking to become a member?
                </p>
                <ButtonLink
                  text={"Become a Member"}
                  buttonColor={"yellow"}
                  link={"/join-us"}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Spacer top={`yellow`} bottom={`black`} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      slug
      title
      date(formatString: "DD MMMM YYYY")
      newsPostDetails {
        endDate
      }
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
  }
`

export default Post

const Expired = () => {
  const [countDown, setCountDown] = React.useState(5)
  useEffect(() => {
    // countDown by -1 every second
    const interval = setInterval(() => {
      setCountDown(countDown - 1)
    }, 1000)
    // clear interval if countDown is 0
    if (countDown <= 0) {
      clearInterval(interval)
      navigate("/")
    }
    return () => clearInterval(interval)
  }, [countDown])

  return (
    <Layout path={pageContext.pagePath}>
      <Wrapper>
        <h1>This post has expired {countDown}</h1>
        <Spinner />
      </Wrapper>
    </Layout>
  )
}
